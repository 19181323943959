<script setup>
import { computed } from 'vue'
import { useGameStore } from '@/stores/gameStore'

const gameStore = useGameStore()

const showPreloader = computed(() => {
  if (gameStore.weAreInGame) return 0
  if (gameStore.progressStatus == 'error') return 0
  if (gameStore.routePath == 'sharedLink') return 0
  return 1
})
</script>

<template>
  <div v-if="showPreloader" class="preloader">
    <div class="preloader-wrapper">
      <svg class="preloader-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <circle fill="#0178F8" stroke="#0178F8" stroke-width="5" r="15" cx="40" cy="100">
          <animate
            attributeName="opacity"
            calcMode="spline"
            dur="2"
            values="1;0;1;"
            keySplines=".5 0 .5 1;.5 0 .5 1"
            repeatCount="indefinite"
            begin="-.4"
          ></animate>
        </circle>
        <circle fill="#0178F8" stroke="#0178F8" stroke-width="5" r="15" cx="100" cy="100">
          <animate
            attributeName="opacity"
            calcMode="spline"
            dur="2"
            values="1;0;1;"
            keySplines=".5 0 .5 1;.5 0 .5 1"
            repeatCount="indefinite"
            begin="-.2"
          ></animate>
        </circle>
        <circle fill="#0178F8" stroke="#0178F8" stroke-width="5" r="15" cx="160" cy="100">
          <animate
            attributeName="opacity"
            calcMode="spline"
            dur="2"
            values="1;0;1;"
            keySplines=".5 0 .5 1;.5 0 .5 1"
            repeatCount="indefinite"
            begin="0"
          ></animate>
        </circle>
      </svg>
    </div>
  </div>
</template>

<style scoped>
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.8); /* Полупрозрачный фон */
  color: #333;
  z-index: 9999; /* Поверх остальных элементов */
}
.preloader-wrapper {
  width: 50px;
}
</style>
