<script setup>
import { useGameStore } from '@/stores/gameStore'
const gameStore = useGameStore()
</script>

<template>
  <div v-if="gameStore.progressStatus == 'error'" class="error-container">
    <div class="error-content">
      <h1>{{ $t('errors.error') }}</h1>
      <p>{{ gameStore.gameErrorMsg }}</p>
    </div>
  </div>
</template>

<style scoped>
.error-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  z-index: 9999;
}

.error-content {
  padding: 10px;
  text-align: center;
}
</style>
