<!-- Вкладка чатов -->

<script setup></script>

<template>
  <div class="tab-wrapper" id="tabChats">
    <div class="tab-chats-header">
      <h1 class="tab-h1">{{ $t('chats.title') }}</h1>
    </div>

    <div class="tab-chats-content content-text">{{ $t('chats.disabled') }}</div>
  </div>
</template>

<style scoped>
/* Сохраняем content-text, tab-wrapper и tab-h1 без изменений */
.content-text {
  font-size: 18px;
  word-break: break-word;
}

/* Новые названия классов */
.tab-chats-content {
  margin-top: 17px;
}

.tab-chats-header,
.tab-chats-content,
.content-text {
  padding: 0 17px;
  margin: 0;
  margin-bottom: 17px;
}
</style>
