<!-- Выбор этапа -->

<script setup>
import { useGameStore } from '@/stores/gameStore'
const gameStore = useGameStore()

const ClickChooseStage = (stageId) => {
  if (gameStore.allowReturnToPreviousStage || gameStore.progressStatus == 'choose-stage')
    gameStore.ChooseStage(stageId)
  else {
    gameStore.dialogs.sureChooseStage.open = 1
    gameStore.dialogs.sureChooseStage.toStageId = stageId
  }
}
</script>

<template>
  <div class="tab-wrapper flex-1 mt-30" id="tabChooseStage">
    <!-- Заголовок -->
    <div class="tab-choose-stage-header">
      <h1 class="tab-h1">{{ $t('chooseStage.title') }}</h1>
    </div>

    <div
      v-if="gameStore.progressStatus == 'choose-stage' || gameStore.allowChooseStage == '1'"
      class="tab-choose-stage-content"
    >
      <div class="">
        <!-- Кнопки выбора этапа -->
        <div class="tab-choose-stage-btn-container">
          <button
            v-for="stage in gameStore.stagesForChoose"
            type="button"
            class="btn tab-choose-stage-btn"
            :disabled="stage.used == 1 || stage.id == gameStore.stage.id"
            @click="ClickChooseStage(stage.id)"
          >
            {{ stage.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.tab-choose-stage-header,
.tab-choose-stage-btn-container {
  /* padding: 0 17px; */
  margin: 0;
  margin-bottom: 17px;
}

.tab-choose-stage-btn {
  margin-top: 10px;
  background-color: #fddb1b;
  width: 100%;
  border: 0;
  padding: 12px;
}

.tab-choose-stage-btn:disabled {
  background: #abb0bc;
  cursor: default;
}

.tab-choose-stage-btn:first-child {
  margin-top: 0;
}

.tab-choose-stage-btn:last-child {
  margin-bottom: 17px;
}
.mt-30 {
  margin-top: 30px;
}
</style>
