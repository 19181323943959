<!-- Вкладка подсказок -->

<script setup>
import StageTelemetryData from '@/components/StageTelemetryData.vue'
import Prorgessbar from '@/components/Progressbar.vue'
import { useGameStore } from '@/stores/gameStore'
const gameStore = useGameStore()
</script>

<template>
  <div class="tab-wrapper tab-wrapper-telemetry">
    <StageTelemetryData />
  </div>
  <div class="tab-wrapper flex-1" id="tab-tips">
    <!-- Заголовок -->
    <div class="tab-tips-header">
      <h1 class="tab-h1">{{ $t('tips.title') }}</h1>
    </div>

    <!-- Подсказок нет -->
    <div class="tab-tips-body content-text" v-if="!gameStore.stage.tips.shown.length">
      {{ $t('tips.empty') }}
    </div>

    <!-- Подсказки -->
    <div class="tab-tips-body" v-else>
      <div v-for="tip in gameStore.stage.tips.shown">
        <div class="tab-tips-tip-container">
          <h2 class="tab-tip-title">{{ tip?.title }}</h2>
          <div v-html="tip?.content?.blocks" class="content-container"></div>
        </div>
      </div>
    </div>
    <Prorgessbar :inCard="1" />
  </div>
</template>

<style scoped>
.tab-tips-body {
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.tab-tips-tip-container {
  border-left: 2px solid #0078f7;
  padding-left: 10px;
  margin-top: 45px;
}

.tab-tips-header {
  margin: 0;
  margin-bottom: 17px;
}

.tab-tip-title {
  font-size: 18px;
  font-weight: bold;
}

/* > 1024 */
@media (min-width: 1024px) {
  .tab-tips-body > div {
  }

  .tab-tips-tip-container {
    margin: 0;
  }
}
</style>
