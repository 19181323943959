<!-- Header мобилка -->

<script setup>
import { useGameStore } from '@/stores/gameStore'
import StageTelemetryData from '@/components/StageTelemetryData.vue'
const gameStore = useGameStore()
</script>

<template>
  <div
    v-if="gameStore.stage.hideInterface == 0 || gameStore.progressStatus == 'choose-stage'"
    class="mobile-header"
  >
    <div class="mobile-header-container">
      <div class="header-elements">
        <!-- <img class="logo" src="@/assets/logo12.svg" alt="logo" /> -->
        <div class="logo-12"></div>
      </div>
      <!-- Блок - с данными команды -->
      <div v-if="gameStore.showOnStageData" class="mobile-onstage-data">
        <StageTelemetryData />
      </div>
    </div>
  </div>
</template>

<style scoped>
.logo-12 {
  height: 26px;
  width: 150px;
  background-image: url(/src/assets/logo12.svg);
}
.mobile-onstage-data {
  /* margin-top: 17px; */
}
.mobile-header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.mobile-header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin: 17px auto 0 auto; */
  padding: 17px;
  max-width: 620px;
  width: 100%;
  gap: 17px;
}
.header-elements {
  display: flex;
  align-items: center;
}

@media (min-width: 1024px) {
  .mobile-header {
    display: none;
  }
}
</style>
