<script setup>
import { timeFunctions } from '@/services/time'
import TestConsole from '@/components/TestConsole.vue'
import { useGameStore } from '@/stores/gameStore'
const gameStore = useGameStore()
</script>

<template>
  <div v-if="gameStore.progressStatus == 'game-start'">
    <div class="screen-game-start">
      <TestConsole />
      <div class="game-start-container">
        <div class="game-start-empty-block">&nbsp;</div>
        <div class="game-start-content">
          <div class="logo">
            <!-- <img class="logo" src="@/assets/logo12-white-2.svg" alt="logo" /> -->
            <div class="logo-12"></div>
          </div>
          <div class="game-start-msg-text">
            {{ $t('timerToGame.gameWillStartAt') }}
            {{ timeFunctions.UTCToLocal(gameStore.game.dtStart) }}
          </div>
        </div>
        <div class="game-start-engine-ver">quest engine {{ gameStore.appVer }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.game-start-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.game-start-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.logo {
  margin-bottom: 38px;
  height: 38px;
  width: auto;
}
.logo-12 {
  height: 38px;
  width: 240px;
}
.game-start-engine-ver {
  margin-bottom: 1rem;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.screen-game-start {
  min-height: 100vh;
  width: 100%;
  background: url('@/assets/path-line.svg'),
    linear-gradient(147.65deg, #05081b 22.93%, #08124b 82.34%);
  background-size: auto;
  background-position: 0 10%;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .game-start-container {
    background: none;
  }
}
</style>
