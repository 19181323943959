<script setup>
import TestConsole from '@/components/TestConsole.vue'
import { useGameStore } from '@/stores/gameStore'
const gameStore = useGameStore()
</script>

<template>
  <!-- Финиш игры -->
  <div v-if="gameStore.progressStatus == 'game-over'">
    <div class="finish-screen">
      <TestConsole />

      <div class="finish-container">
        <div class="finish-empty-block">&nbsp;</div>
        <div class="finish-content">
          <div class="logo">
            <!-- <img class="logo" src="@/assets/logo12-white-2.svg" alt="logo" /> -->
            <div class="logo-12"></div>
          </div>
          <div class="finish-msg-text">
            {{ gameStore.game.finalText }}<br /><br />{{ $t('gameOver.promo') }}
            <a :href="$t('gameOver.landingLink')">12codes.com</a>
          </div>
        </div>
        <div class="finish-engine-ver">quest engine {{ gameStore.appVer }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.finish-screen {
  min-height: 100vh;
  width: 100%;
  background: url('@/assets/path-line.svg'),
    linear-gradient(147.65deg, #05081b 22.93%, #08124b 82.34%);
  background-size: auto;
  background-position: 0 10%;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.finish-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.finish-empty-block,
.finish-engine-ver {
  margin: 15px 0;
}

.finish-content {
  width: 100%;
  max-width: 620px;
  padding: 17px 17px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

/* Класс logo оставляем без изменений */
.logo {
  margin-bottom: 38px;
  height: 38px;
  width: auto;
}
.logo-12 {
  height: 38px;
  width: 240px;
}

.finish-engine-ver {
  margin-bottom: 1rem;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.finish-msg-text a {
  text-decoration: underline;
  color: #ffffff;
}
</style>
