<!-- Вкладка подсказок -->

<script setup>
import { useGameStore } from '@/stores/gameStore'
const gameStore = useGameStore()
</script>

<template>
  <div class="tab-wrapper flex-1 mt-30" id="tab-logs">
    <div class="tab-logs-telemetry">
      <div>{{ gameStore.stageTelemetry.stopwatch }}</div>
      <div>{{ gameStore?.stage?.correctAnswers }}/{{ gameStore?.stage?.reqAnswers }}</div>
    </div>
    <div class="tab-logs-remaining" v-if="gameStore?.stage?.difficulties?.length">
      <div class="tab-logs-remaining-header">{{ $t('answersLogs.remainingTitle') }}</div>
      <div class="tab-logs-remaining-list" v-html="gameStore.PrepareRemainingAnswersList(1)"></div>
    </div>
    <div class="tab-logs-answers">
      <div class="tab-logs-answers-header">{{ $t('answersLogs.title') }}</div>
      <div
        v-if="gameStore?.teamAnswersList?.length"
        class="tab-logs-answers-table"
        v-html="gameStore.PrepareTeamAnswersList()"
      ></div>
      <div class="tab-logs-answers-table" v-else>
        {{ $t('answersLogs.empty') }}
      </div>
    </div>
    <div class="tab-logs-back-container">
      <RouterLink to="/" class="btn btn-default tab-logs-back-btn">
        {{ $t('answersLogs.btnBack') }}
      </RouterLink>
    </div>
  </div>
</template>

<style scoped>
.tab-logs-telemetry {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.tab-logs-telemetry > * {
  background: #eeeeee80;
  padding: 20px;
  border-radius: 10px;
  flex: 1;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
}

.tab-logs-remaining {
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #eeeeee80;
  padding: 30px 20px;
  border-radius: 10px;
}
.tab-logs-remaining-header {
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
}

.tab-logs-answers {
  margin: 10px 0 100px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #eeeeee80;
  padding: 30px 20px;
  border-radius: 10px;
}

.tab-logs-answers-header {
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
}

.tab-logs-answers-table {
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-top: 12px;
}

.tab-logs-back-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -4px 10px 0px #8e8c8c1a;
  border-radius: 20px;
  justify-content: center;
}

.tab-logs-back-btn {
  text-align: center;
  width: calc(100% - 34px);
}
.mt-30 {
  margin-top: 30px;
}

/* > 1024 */
@media (min-width: 1024px) {
  .tab-logs-back-container {
    position: relative;
    box-shadow: none;
  }
  .tab-logs-back-btn {
    width: 100%;
  }
}
</style>
