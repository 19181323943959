<script setup>
import { computed } from 'vue'
import { timeFunctions } from '@/services/time'
import { useGameStore } from '@/stores/gameStore'

const gameStore = useGameStore()

const showProgressBarAP = computed(() => {
  return gameStore.secondsUntilAP > 0 && gameStore.currentTab != 'answersLog'
})

const props = defineProps({
  inCard: Number
})
</script>

<template>
  <div v-if="showProgressBarAP" class="progress-bar-ap" :class="{ 'in-card': props.inCard }">
    <div
      class="progress-bar-container"
      :data-label="
        $t('progressBar.untilStageStop') + timeFunctions.MSToHumanTime(gameStore.secondsUntilAP)
      "
    >
      <span class="progress-bar-label" :style="'width:' + gameStore.progressAP + '%;'"></span>
    </div>
  </div>
</template>

<style scoped>
/* Прогрессбар */
.progress-bar-ap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  position: sticky;
  bottom: 0;
}
.in-card {
  display: none;
}
.progress-bar-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  height: 1.6em;
  width: 100%;
  background: #b3c3d2;
  position: relative;
  color: #ffffff;
}
.progress-bar-container:before {
  content: attr(data-label);
  font-size: 0.8em;
  position: absolute;
  text-align: center;
  top: 5px;
  left: 0;
  right: 0;
}
.progress-bar-label {
  background: linear-gradient(90deg, #0072ff, #00c6ff);
  display: inline-block;
  height: 100%;
  font-size: 14px;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .in-card {
    display: block;
    margin-top: auto;
    margin-bottom: -32px;
    margin-left: -16px;
    margin-right: -16px;
  }
  .progress-bar-ap:not(.in-card) {
    display: none;
  }
}
</style>
