<!-- Вкладка текущего этапа (задание) -->

<script setup>
import Prorgessbar from '@/components/Progressbar.vue'
import { useGameStore } from '@/stores/gameStore'
import { computed } from 'vue'

const gameStore = useGameStore()
const stageContent = computed(() => {
  return gameStore.stage?.content?.blocks ?? null
})

const ClickNextStage = (stageId) => {
  if (gameStore.stage?.buttons?.[0]?.advData == 'confirmIt') {
    gameStore.dialogs.sureNextStage.open = 1
    gameStore.dialogs.sureNextStage.fromStageId = stageId
  } else {
    gameStore.GoToNextStage(stageId)
  }
}
</script>

<template>
  <div class="tab-wrapper">
    <!-- Название этапа -->
    <div class="tab-stage-header">
      <h1 v-html="gameStore.stage.name" class="tab-h1"></h1>
    </div>

    <!-- Контент -->
    <div class="tab-stage-body">
      <div v-html="stageContent" class="content-container"></div>
    </div>

    <!-- Ввод ответа -->
    <div
      class="tab-stage-control"
      :class="{
        'text-success': gameStore.lastAnswerResult?.result == 'correct',
        'text-danger': gameStore.lastAnswerResult?.result == 'wrong',
        'text-warning': gameStore.lastAnswerResult?.result == 'again'
      }"
      v-if="gameStore.stage.answerType"
    >
      <div class="tab-stage-answer-input-container">
        <input
          class="tab-stage-answer-input"
          autocomplete="off"
          v-model="gameStore.answerInput"
          @keyup.enter="gameStore.SendAnswer"
          :placeholder="$t('stage.answerInputPlaceholder')"
        />
        <button class="btn tab-stage-answer-input-btn" @click="gameStore.SendAnswer">
          {{ $t('stage.send') }}
        </button>
      </div>
      <div
        v-if="gameStore.lastAnswerResult.result && !gameStore.lastAnswerResult.invisible"
        ref="targetElement"
        class="tab-stage-answer-result"
      >
        {{ gameStore.lastAnswerResult?.answer }} -
        {{ gameStore.lastAnswerResult?.result == 'correct' ? $t('answersLogs.correct') : '' }}
        {{ gameStore.lastAnswerResult?.result == 'wrong' ? $t('answersLogs.wrong') : '' }}
        {{ gameStore.lastAnswerResult?.result == 'again' ? $t('answersLogs.again') : '' }}
      </div>
    </div>

    <!-- доп. кнопки -->
    <div v-if="gameStore.stage?.buttons?.length" class="tab-stage-control">
      <button class="btn btn-outline-warning w-100" @click="ClickNextStage(gameStore.stage.id)">
        {{ gameStore.stage.buttons[0].btnCaption }}
      </button>
    </div>
    <div style="padding: 5px"></div>
    <Prorgessbar :inCard="1" />
  </div>
</template>

<style scoped>
.tab-stage-header {
  padding: 0 17px;
  margin: 0;
  margin-bottom: 17px;
}

.tab-stage-body {
  margin-bottom: 17px;
}

.tab-stage-control {
  padding: 0 17px;
  margin-top: 17px;
}

.tab-stage-answer-input-container {
  position: relative;
  width: 100%;
  font-size: 16px;
}

.tab-stage-answer-input {
  display: block;
  width: 100%;
  height: 48px;
  line-height: 3em;
  border-radius: 10px;
  border: 1px solid #abb0bc;
  padding: 0 15px;
}

.tab-stage-answer-input:focus {
  outline: none;
  border-color: #1c1c1c;
}

.tab-stage-answer-input-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 48px;
  border-radius: 10px;
  border: 1px solid #fddb1b;
  background-color: #fddb1b;
  padding: 0 20px;
}

.tab-stage-answer-result {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}
</style>
