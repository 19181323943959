<!-- Header мобилка -->

<script setup>
import { computed } from 'vue'
import { useGameStore } from '@/stores/gameStore'
const gameStore = useGameStore()

const needTextAnswers = computed(() => {
  return gameStore?.stage?.answerType
})
</script>

<template>
  <div class="onstage-data">
    <div class="onstage-data-telemetry">
      <div class="onstage-data-two-elements">
        <div class="onstage-time" v-if="gameStore.stageTelemetry.stopwatch">
          <span class="desktop-onstage-time-text">
            {{ $t('stageTelemetry.timeOnStage') }}&nbsp;&nbsp;
          </span>
          <span>{{ gameStore.stageTelemetry.stopwatch }}</span>
          <span v-if="gameStore.stageTelemetry.ap">
            {{ '&nbsp;/&nbsp;' + gameStore.stageTelemetry.ap }}
          </span>
        </div>
        <div class="onstage-answers" v-if="needTextAnswers">
          <span class="desktop-onstage-answers-text"
            >{{ $t('stageTelemetry.correctAnswers') }}&nbsp;
          </span>
          <span class="mobile-onstage-answers-text">
            {{ $t('stageTelemetry.answers') }}&nbsp;
          </span>
          <span>{{ gameStore?.stage?.correctAnswers }}/{{ gameStore?.stage?.reqAnswers }}</span>
        </div>
      </div>
      <div
        class="remaining-answers"
        v-if="needTextAnswers && gameStore?.stage?.difficulties?.length"
      >
        <span class="desktop-onstage-answers-text"
          >{{ $t('stageTelemetry.remainingAnswers') }}&nbsp;
        </span>
        <span class="mobile-onstage-answers-text">
          {{ $t('stageTelemetry.remaining') }}&nbsp;
        </span>
        <span v-html="gameStore.PrepareRemainingAnswersList(0)" class="remaining-answer"></span>
      </div>
    </div>
    <RouterLink to="/logs" class="button-answers-log" v-if="needTextAnswers">
      {{ $t('stageTelemetry.details') }}
    </RouterLink>
  </div>
</template>

<style scoped>
.onstage-data {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 17px;
  /* color: #636363; */
  color: #000000;
  font-size: 14px;
  width: 100%;
}
.onstage-time {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
.desktop-onstage-time-text {
  display: none;
}
.desktop-onstage-answers-text {
  display: none;
}
.mobile-onstage-data {
  margin-top: 12px;
}
.button-answers-log {
  flex: 0;
  padding: 5px 15px;
  border: 1px solid #fddb1b;
  background: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  /* color: #636363; */
  color: #000000;
}
.button-answers-log:hover {
  background: #fddb1b;
}
.onstage-data-telemetry {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.onstage-data-two-elements {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 17px;
}
.onstage-answers {
  display: flex;
  flex-direction: row;
}
.remaining-answers {
  display: flex;
  flex-direction: row;
}
.remaining-answer {
  color: #7f7f7f;
}

@media (min-width: 1024px) {
  .desktop-onstage-time-text,
  .desktop-onstage-answers-text {
    display: block;
  }
  .desktop-onstage-data .onstage-data {
    display: flex;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
  }
  .desktop-onstage-data .onstage-time {
    display: flex;
  }
  .mobile-onstage-answers-text {
    display: none;
  }
  .mobile-onstage-data {
    display: none;
  }
  /* .button-answers-log {
    padding: 0;
    border: 0;
    background: transparent;
    border-radius: 0;
    cursor: pointer;
    text-decoration: underline;
    color: #ffffff;
  } */
  /* .button-answers-log:hover {
    background: transparent;
  } */
}
</style>
