<script setup>
import dialogPolyfill from 'dialog-polyfill'
import { onMounted, watch } from 'vue'

import { useGameStore } from '@/stores/gameStore'
const gameStore = useGameStore()

let modal = null
onMounted(() => {
  modal = document.querySelector('#dialogStageTransition')
  dialogPolyfill.registerDialog(modal)
})

watch(
  () => gameStore.lastAnswerResult?.answer,
  (newValue) => {
    if (newValue == null) return 0

    // только при правильном ответе и при этом должен быть переход на другой этап
    if (
      gameStore.lastAnswerResult?.result == 'correct' &&
      gameStore?.stage?.id !== null &&
      gameStore.lastAnswerResult?.stageId != gameStore?.stage?.id
    ) {
      modal.showModal()
      setTimeout(() => {
        modal.close()
      }, 2500)
    }
  }
)
</script>

<template>
  <dialog id="dialogStageTransition">
    <div class="modal-box">
      <div class="dialog-body">
        <div class="text-success stage-transition-answer-correct">
          <span class="stage-transition-answer">{{ gameStore.lastAnswerResult?.answer }}</span> -
          {{ $t('dialogs.stageTransition.correctAnswer') }}
        </div>
        <div class="stage-transition-go-next">
          {{ $t('dialogs.stageTransition.goNext') }}
        </div>
      </div>
    </div>
  </dialog>
</template>

<style scoped>
.dialog-body {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
}
.stage-transition-go-next {
  margin-top: 17px;
}
.stage-transition-answer {
  word-break: break-all;
}
.stage-transition-answer-correct {
  text-align: center;
}
</style>
